import isNil from 'lodash/isNil';
/**
 * Data returned from our API containing personalized insights/display info specific to a connection
 */
export type Insights = {
	firstName: string;
	lastName: string;
	title?: string;
	commStyle: CommunicationStyle;
	secondaryCommStyle?: CommunicationStyle;
	liAvatarUrl?: string;
	alias?: string;
};

/**
 * Possible communication styles
 */
const COMMUNICATION_STYLES = {
	director: 'director',
	relator: 'relator',
	socializer: 'socializer',
	thinker: 'thinker',
} as const;

/**
 * All possible communication styles as an array
 */
const ALL_COMMUNICATION_STYLES = Object.values<string>(COMMUNICATION_STYLES);

/**
 * Possible communication styles
 */
export type CommunicationStyle = ValueOf<typeof COMMUNICATION_STYLES>;

/**
 * Validates the provided string is a communication style type
 * @param value
 * @returns
 */
export function isCommunicationStyle(value: string | undefined): value is CommunicationStyle {
	if (isNil(value)) {
		return false;
	}
	return ALL_COMMUNICATION_STYLES.includes(value);
}
