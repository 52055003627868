import { ProspectInfoForm } from '@copilot/common/components/drawer/wrappers/contact/informationPanel/prospectInfoSection/prospectInfoForm';
import styles from './prospectInfo.module.less';
import { DRAWER_PROSPECT_INFO_TOGGLE_TRACKING_ID } from '@copilot/common/tracking/userpilotEventConsts';
import { ComponentProps } from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

type ProspectInfoV2DrawerSectionProps = {
	prospectInfoFormProps: ComponentProps<typeof ProspectInfoForm>;
};

/**
 * The Prospect Info section of the drawer, listing the contact's company, title, email, and phone number.
 * @param props
 * @constructor
 */
export function ProspectInfoV2DrawerSection({
	prospectInfoFormProps,
}: ProspectInfoV2DrawerSectionProps) {
	return (
		<div className={styles.infoPanelDrawerSection}>
			<Title
				className={styles.infoPanelTitle}
				data-tracking-id={DRAWER_PROSPECT_INFO_TOGGLE_TRACKING_ID}
				level={4}
			>
				Prospect Info
			</Title>
			<div className={styles.prospectInfoFormWrapper}>
				<ProspectInfoForm {...prospectInfoFormProps} />
			</div>
		</div>
	);
}
