import { Button, Flex, Typography } from 'antd';
import { Icon } from '@iconify/react';
import { aiIcon } from '@copilot/common/constant/icons';
import { PersonalizedInsightsProps } from '@copilot/common/components/drawer/wrappers/contact/informationPanel/types';
import styles from './infoPanel.module.less';
import { PERSONALIZED_INSIGHTS_BUTTON_TEST_ID } from '../../../contactDrawerV2/infoPanelV2/constants';
import { DRAWER_VIEW_INSIGHTS_TRACKING_ID } from '@copilot/common/tracking/userpilotEventConsts';
import { useFeatureFlags } from '@copilot/common/hooks/useFeatureFlags/useFeatureFlags';
import { AiButton } from '@copilot/common/components/ai/aiButton/aiButton';
import { AI_TAG_SIZES } from '@copilot/common/components/ai/aiTag/types';
import { PersonalizedInsightsSectionV2DrawerSection } from '../personalizedInsightsSectionV2/personalizedInsightsSectionV2DrawerSection';

const { Title, Text } = Typography;

/**
 * The personalized insight section of prospect info panel
 * @param props
 * @constructor
 */
export default function PersonalizedInsightsDrawerSection({
	onClick,
	onNotAvailable,
	isEnabled,
	isLoadingData,
	communicationStyles,
}: PersonalizedInsightsProps) {
	const { isNewAiUiFeatureEnabled, isNewPersonalizedInsightsFeatureEnabled } = useFeatureFlags();
	if (isNewPersonalizedInsightsFeatureEnabled) {
		return (
			<PersonalizedInsightsSectionV2DrawerSection
				communicationStyles={communicationStyles}
				isLoadingInsights={isLoadingData}
				onRequestInsights={onClick}
				onSeeMoreTips={onClick}
			/>
		);
	}

	return (
		<Flex
			vertical
			gap="small"
			className={[
				styles.personalizedInsightsWrapper,
				isNewAiUiFeatureEnabled ? styles.personalizedInsightsAi : '',
			].join(' ')}
		>
			<div>
				<Title className={styles.infoPanelTitle}>
					{isNewAiUiFeatureEnabled
						? 'Communication Style Recommendation'
						: 'Personalized Insights'}
				</Title>
				<Text className={styles.insightsDescription}>
					{isNewAiUiFeatureEnabled
						? 'Tailor your messages for better results'
						: 'Get profile insights about this prospect'}
				</Text>
			</div>

			{isNewAiUiFeatureEnabled ? (
				<AiButton
					data-testid={PERSONALIZED_INSIGHTS_BUTTON_TEST_ID}
					className={styles.viewInsightsButton}
					size={AI_TAG_SIZES.MEDIUM}
					isLoading={isLoadingData}
					onClick={() => {
						isEnabled ? onClick() : onNotAvailable();
					}}
					data-tracking-id={DRAWER_VIEW_INSIGHTS_TRACKING_ID}
				>
					View Personalized Insights
				</AiButton>
			) : (
				<Button
					data-testid={PERSONALIZED_INSIGHTS_BUTTON_TEST_ID}
					className={styles.viewInsightsButton}
					size={'large'}
					loading={isLoadingData}
					block={true}
					icon={<Icon inline icon={aiIcon} />}
					onClick={() => {
						isEnabled ? onClick() : onNotAvailable();
					}}
					data-tracking-id={DRAWER_VIEW_INSIGHTS_TRACKING_ID}
				>
					View insights
				</Button>
			)}
		</Flex>
	);
}
