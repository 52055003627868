import { Button, Collapse, Typography } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import styles from './personalizedInsightsSectionV2.module.less';
import { AiIcon } from '@copilot/common/components/ai/aiIcon/aiIcon';
import { useState } from 'react';

const { Title, Text } = Typography;

type CommunicationTipsCollapsableSectionProps = {
	communicationDos: string[];
	communicationDonts: string[];
	onSeeMoreTips: () => Promise<void>;
};

/**
 * The collapsable section for the communication tips, associated with the personalized insights results
 * @param props
 * @constructor
 */
export function CommunicationTipsCollapsableSection({
	communicationDos,
	communicationDonts,
	onSeeMoreTips: handleSeeMoreTips,
}: CommunicationTipsCollapsableSectionProps) {
	const [isLoading, setIsLoading] = useState(false);

	async function onSeeMoreTips() {
		setIsLoading(true);
		try {
			await handleSeeMoreTips();
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<Collapse
			ghost
			className={styles.communicationTipsCollapse}
			items={[
				{
					label: (
						<Title className={styles.infoPanelTitle}>
							Communication Tips <AiIcon />
						</Title>
					),
					children: (
						<div className={styles.communicationTipsWrapper}>
							{!isEmpty(communicationDos) && (
								<div className={styles.communicationTipDo}>
									<Title className={styles.communicationTipTitle} level={5}>
										<CheckCircleOutlined />
										Do
									</Title>
									{communicationDos[0]}
								</div>
							)}
							{!isEmpty(communicationDonts) && (
								<div className={styles.communicationTipDont}>
									<Title className={styles.communicationTipTitle} level={5}>
										<CloseCircleOutlined />
										Don't
									</Title>
									{communicationDonts[0]}
								</div>
							)}
							<Button
								type="link"
								className={styles.seeMoreTipsButton}
								onClick={() => void onSeeMoreTips()}
								loading={isLoading}
							>
								See more tips here
							</Button>
							<Text className={styles.smartReplyTipText}>
								Apply the prospect's communication style to <b>Smart Reply</b>
							</Text>
						</div>
					),
				},
			]}
		/>
	);
}
