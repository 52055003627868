import { Tag } from 'antd';
import styles from './personalizedInsightsV2CommonStyles.module.less';
import {
	BulbOutlined,
	CompassOutlined,
	EnvironmentOutlined,
	SmileOutlined,
	TeamOutlined,
	BookOutlined,
	HeartOutlined,
	SearchOutlined,
	InfoCircleOutlined,
} from '@ant-design/icons';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { CommunicationStyle } from './personalizedInsightsV2Types';

/**
 * Get the communication style tags for the header based on the communication style
 * @param commStyle
 * @returns
 */
export function getCommunicationStyleDescriptorTags(commStyle: CommunicationStyle) {
	switch (commStyle) {
		case 'director':
			return (
				<div
					className={[styles.director, styles.communicationStyleDescriptorTags].join(' ')}
				>
					<Tag>
						<BulbOutlined /> Straightforward and Decisive
					</Tag>
					<Tag>
						<CompassOutlined /> Goal-Oriented
					</Tag>
					<Tag>
						<EnvironmentOutlined /> Results-driven
					</Tag>
				</div>
			);
		case 'relator':
			return (
				<div
					className={[styles.relator, styles.communicationStyleDescriptorTags].join(' ')}
				>
					<Tag>
						<TeamOutlined /> Trust-Driven
					</Tag>
					<Tag>
						<HeartOutlined /> Empathetic Collaborator
					</Tag>
					<Tag>
						<SmileOutlined /> Harmony Seeker
					</Tag>
				</div>
			);
		case 'thinker':
			return (
				<div
					className={[styles.thinker, styles.communicationStyleDescriptorTags].join(' ')}
				>
					<Tag>
						<SearchOutlined /> Data-Backed
					</Tag>
					<Tag>
						<InfoCircleOutlined /> Detail-Oriented
					</Tag>
					<Tag>
						<BulbOutlined /> Logic-Driven
					</Tag>
				</div>
			);
		case 'socializer':
			return (
				<div
					className={[styles.socializer, styles.communicationStyleDescriptorTags].join(
						' '
					)}
				>
					<Tag>
						<SmileOutlined /> Energetic and Friendly
					</Tag>
					<Tag>
						<TeamOutlined /> Relationship-Builder
					</Tag>
					<Tag>
						<BookOutlined /> Storytelling Focus
					</Tag>
				</div>
			);
		default:
			return UtilityFunctions.assertUnreachable(commStyle);
	}
}
