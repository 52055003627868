// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qyKvhvjzcil0vzonmmWp {\n  color: #262626;\n  font-weight: 400;\n  background: #f5f5f5;\n  user-select: none;\n  flex-shrink: 0;\n  font-size: 12px;\n  align-items: center;\n  justify-content: center;\n  display: flex;\n}\n.mJNruSBVguxgp09OfER8 {\n  width: 32px;\n  height: 32px;\n  border-radius: 2px;\n  font-size: 14px;\n}\n.CPXLgftSRgLJ1wLOK6a8 {\n  width: 24px;\n  height: 24px;\n  border-radius: 4px;\n  font-size: 12px;\n}\n.RaWltad8jbZ5Y1W_IHGw {\n  width: 40px;\n  height: 40px;\n  border-radius: 6px;\n  font-size: 12px;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/componentModels/activities/conversationWrapper/item/avatar.module.less"],"names":[],"mappings":"AACA;EACC,cAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;EAEA,mBAAA;EACA,uBAAA;EACA,aAAA;AADD;AAIA;EACC,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AAFD;AAKA;EACC,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AAHD;AAMA;EACC,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AAJD","sourcesContent":["@import '@copilot/styles/antd-variables.less';\n.avatar {\n\tcolor: #262626;\n\tfont-weight: 400;\n\tbackground: @Gray-3;\n\tuser-select: none;\n\tflex-shrink: 0;\n\tfont-size: 12px;\n\n\talign-items: center;\n\tjustify-content: center;\n\tdisplay: flex;\n}\n\n.inbox {\n\twidth: 32px;\n\theight: 32px;\n\tborder-radius: 2px;\n\tfont-size: 14px;\n}\n\n.message {\n\twidth: 24px;\n\theight: 24px;\n\tborder-radius: 4px;\n\tfont-size: 12px;\n}\n\n.contact {\n\twidth: 40px;\n\theight: 40px;\n\tborder-radius: 6px;\n\tfont-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "qyKvhvjzcil0vzonmmWp",
	"inbox": "mJNruSBVguxgp09OfER8",
	"message": "CPXLgftSRgLJ1wLOK6a8",
	"contact": "RaWltad8jbZ5Y1W_IHGw"
};
export default ___CSS_LOADER_EXPORT___;
