import { Spin, Tag } from 'antd';
import styles from './aiTag.module.less';
import { AI_TAG_SIZES, AiTagSize } from './types';
import { LoadingOutlined } from '@ant-design/icons';
import { AiIcon } from '../aiIcon/aiIcon';

type AiTagProps = {
	children: any;
	isReadonly?: boolean;
	isClickable?: boolean;
	isLoading?: boolean;
	suffixContent?: any;
	size?: AiTagSize;
};

/**
 * Tag for ai features, can be readonly or used as part of a button/dropdown trigger/etc
 * @param param0
 * @returns
 */
export function AiTag({
	children,
	isReadonly = false,
	isClickable = false,
	isLoading = false,
	suffixContent,
	size = AI_TAG_SIZES.MEDIUM,
}: AiTagProps) {
	return (
		<div
			className={[
				styles.aiTagBorder,
				isReadonly ? styles.readonly : '',
				isClickable ? styles.clickable : '',
				isLoading ? styles.loading : '',
				styles[size],
			].join(' ')}
		>
			<Tag className={styles.aiTag}>
				<div className={styles.textWrapper}>
					{children}
					{!isLoading && <AiIcon className={styles.aiIcon} />}
					{isLoading && (
						<Spin
							size="small"
							indicator={<LoadingOutlined />}
							className={styles.loadingIcon}
						/>
					)}
				</div>
				{isLoading ? null : suffixContent}
			</Tag>
		</div>
	);
}
