import { isEmpty } from 'lodash';
import { useNewProspectDrawer, useOrganizationContact } from './data/hooks';
import ContactDrawerInformationPanel from './informationPanel/informationPanel';
import { Skeleton } from 'antd';
import { OrgContactModel } from '@copilot/common/components/drawer/wrappers/contact/model';
import { useTheme } from 'styled-components';
import { ReminderProps } from './informationPanel/types';
import { handleOpenPersonalizedInsights } from './informationPanel/util';
import { useTermsOfUse } from '@copilot/common/hooks/termsOfUse';
import { TermsOfUse } from '@copilot/data/responses/interface';
import { AIFeatureManager } from '@copilot/data';
import modalManager from '@copilot/common/utils/modalManager';
import { SessionBoundModel } from 'redux-orm';
import { ContactConnection } from '@copilot/common/store/models/redux';
import { PipelineProps } from './contactPipeline/types';

type Props = {
	/**
	 * Contact id
	 */
	contactId: string;
	/**
	 * tags for this contact
	 */
	contactTags: string[];
	/**
	 * value of selected option
	 */
	selectedOrgMemberId: string;
	/**
	 * callback for setting tags
	 */
	setContactTags: (tags: string[]) => void;
	/**
	 * contact connection
	 */
	contactConnection: SessionBoundModel<ContactConnection, {}> | null;
	/**
	 * callback upon connected options select
	 */
	handleMemberSelect: (selectedValue: string) => void;
	/**
	 * callback upon contact update
	 */
	handleContactUpdate: (update: OrgContactModel) => void;
	/**
	 * the profile id of the contact
	 */
	contactAlias?: string;
	/**
	 * whether the current org member is an org owner
	 */
	isOwner: boolean;
	isOpenProfile?: boolean;
	/**
	 * Whether to show a meeting booked state for the button
	 */
	isMeetingBooked: boolean;
	/**
	 * Whether the meeting booked button should be loading
	 */
	isMeetingLoading: boolean;
	/**
	 * Callback after meeting booked is clocked
	 */
	handleMeetingClick: (isBooked: boolean) => void;
	/**
	 * props for set reminder functionality
	 */
	reminderProps: ReminderProps;
	/**
	 * Whether the set reminder button should be loading
	 */
	isReminderLoading: boolean;
	/**
	 * Props for pipeline view
	 */
	pipelineProps: PipelineProps;
	/**
	 * Id for the linkedin profile
	 */
	profileId?: string;
	/**
	 * Callback for saving tags
	 */
	onSaveTags: (tags: string[]) => void;
};

/**
 * [Smart] Contact Drawer's smart container
 * Responsible for handling data fetching and state updates
 * @constructor
 */
export default function ContactDrawerContainer(props: Props) {
	const {
		contactId,
		contactTags,
		selectedOrgMemberId,
		setContactTags,
		contactConnection,
		handleMemberSelect,
		handleContactUpdate,
		isMeetingBooked,
		isMeetingLoading,
		isReminderLoading,
		handleMeetingClick,
		reminderProps,
		contactAlias,
		pipelineProps,
		isOpenProfile = false,
		profileId,
		isOwner,
		onSaveTags,
	} = props;
	const isNewProspectDrawerEnabled = useNewProspectDrawer();
	const { hasAccepted: hasAcceptedAITermsOfUse } = useTermsOfUse(
		TermsOfUse.AIFeature,
		AIFeatureManager.acceptTermsOfUse
	);
	const theme = useTheme();

	const personalizedInsightsId = profileId;
	function openPersonalizedInsights() {
		return handleOpenPersonalizedInsights(personalizedInsightsId ?? '');
	}

	// Prevent the container from rendering if the feature flag is off
	if (!isNewProspectDrawerEnabled) {
		console.warn('Meeting booked feature is off for the current user');
		return null;
	}

	if (isEmpty(contactId)) return <h1>Error</h1>;

	const {
		campaignConnections,
		contact,
		loading,
		isContactTitleUpdating,
		isContactPhoneNumberUpdating,
		isContactCompanyUpdating,
		isContactEmailUpdating,
		onContactPhoneNumberUpdate,
		onContactCompanyUpdate,
		onContactEmailUpdate,
		onContactTitleUpdate,
	} = useOrganizationContact(contactId, handleContactUpdate);

	if (loading)
		return (
			<Skeleton
				style={{
					paddingLeft: theme['@spacer-num-sm'],
					paddingRight: theme['@spacer-num-sm'],
				}}
				active
			/>
		);

	return (
		<ContactDrawerInformationPanel
			contact={contact}
			contactTags={contactTags}
			isOpenProfile={isOpenProfile}
			campaignConnections={campaignConnections}
			selectedOrgMemberId={selectedOrgMemberId}
			contactConnection={contactConnection}
			setContactTags={setContactTags}
			handleMemberSelect={handleMemberSelect}
			prospectInfo={{
				contactCompany: {
					value: contact.company,
					isLoading: isContactCompanyUpdating,
					onUpdate: (company: string) => {
						onContactCompanyUpdate(company);
					},
				},
				contactEmail: {
					value: contact.email,
					isLoading: isContactEmailUpdating,
					onUpdate: (email: string) => {
						onContactEmailUpdate(email);
					},
				},
				contactTitle: {
					value: contact.title,
					isLoading: isContactTitleUpdating,
					onUpdate: (title: string) => {
						onContactTitleUpdate(title);
					},
				},
				contactPhoneNumber: {
					value: contact.phoneNumber,
					isLoading: isContactPhoneNumberUpdating,
					onUpdate: (phoneNumber: string) => {
						onContactPhoneNumberUpdate(phoneNumber);
					},
				},
			}}
			contactAlias={contactAlias}
			reminderProps={reminderProps}
			isReminderLoading={isReminderLoading}
			isMeetingBooked={isMeetingBooked}
			isMeetingLoading={isMeetingLoading}
			handleMeetingClick={handleMeetingClick}
			pipelineProps={pipelineProps}
			personalizedInsightsProps={{
				communicationStyles: campaignConnections.find(
					(connection) => connection.orgMemberId === selectedOrgMemberId
				)?.communicationStyles,
				onClick: openPersonalizedInsights,
				isEnabled: hasAcceptedAITermsOfUse,
				onNotAvailable: () =>
					modalManager.openAIFeatureTermsOfUseModal({
						onSubmit: () => {
							void openPersonalizedInsights();
						},
					}),
				isShown: !isEmpty(personalizedInsightsId),
			}}
			isOwner={isOwner}
			onSaveTags={onSaveTags}
		/>
	);
}
