import { Divider, Skeleton, Tooltip, Typography } from 'antd';
import styles from './personalizedInsightsSectionV2.module.less';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { QuestionCircleOutlined, CloseCircleFilled } from '@ant-design/icons';
import { AiButton } from '@copilot/common/components/ai/aiButton/aiButton';
import { PERSONALIZED_INSIGHTS_BUTTON_TEST_ID } from '../../../contactDrawerV2/infoPanelV2/constants';
import { AI_TAG_SIZES } from '@copilot/common/components/ai/aiTag/types';
import { DRAWER_VIEW_INSIGHTS_TRACKING_ID } from '@copilot/common/tracking/userpilotEventConsts';
import { getInsightsLookup } from '@copilot/common/pages/personalizedInsightsV2/common/personalizedInsightsV2CopyData';
import {
	CommunicationStyle,
	isCommunicationStyle,
} from '@copilot/common/pages/personalizedInsightsV2/common/personalizedInsightsV2Types';
import { CommunicationTipsCollapsableSection } from './communicationTipsCollapsableSection';
import { getCommunicationStyleDisplayText } from './personalizedInsightsSectionV2Helpers';
import { CommunicationStyleIcon } from '../../../../ai/personalizedInsights/communicationStyleIcon';
import { AiIcon } from '@copilot/common/components/ai/aiIcon/aiIcon';
import { getCommunicationStyleDescriptorTags } from '@copilot/common/pages/personalizedInsightsV2/common/personalizedInsightsV2HelperFunctions';
import { CommunicationStylesAnalysis } from '@copilot/common/types/communicationStyles';

const { Title, Text } = Typography;

/**
 * The styles map for the personalized insights section
 */
type StylesMap = {
	primaryStyle?: CommunicationStyle;
	secondaryStyle?: CommunicationStyle;
};

/**
 * The communication tips for the personalized insights section
 */
type CommunicationTips = {
	communicationDos: string[];
	communicationDonts: string[];
};

/**
 * The props for the personalized insights section
 */
type PersonalizedInsightsSectionV2DrawerSectionProps = {
	communicationStyles?: CommunicationStylesAnalysis;
	isLoadingInsights?: boolean;
	onRequestInsights?: () => Promise<void>;
	onSeeMoreTips?: () => Promise<void>;
};

/**
 * The personalized insights section of prospect info panel
 * @param props
 * @constructor
 */
export function PersonalizedInsightsSectionV2DrawerSection({
	communicationStyles,
	isLoadingInsights = false,
	onRequestInsights: handleRequestInsights,
	onSeeMoreTips: handleSeeMoreTips,
}: PersonalizedInsightsSectionV2DrawerSectionProps) {
	const [displayState, setDisplayState] = useState<
		'showStyles' | 'showError' | 'showRequestButton' | 'loading'
	>('loading');
	const [isProcessingRequest, setIsProcessingRequest] = useState(false);
	const [communicationTips, setCommunicationTips] = useState<CommunicationTips>({
		communicationDos: [],
		communicationDonts: [],
	});
	const [stylesMap, setStylesMap] = useState<StylesMap>({});
	useEffect(() => {
		const primaryStyle = communicationStyles?.styles?.[0]?.style;
		const secondaryStyle = communicationStyles?.styles?.[1]?.style;

		const newStylesMap = {
			primaryStyle: isCommunicationStyle(primaryStyle) ? primaryStyle : undefined,
			secondaryStyle: isCommunicationStyle(secondaryStyle) ? secondaryStyle : undefined,
		};

		setStylesMap(newStylesMap);

		const hasStyles =
			isCommunicationStyle(primaryStyle) || isCommunicationStyle(secondaryStyle);
		const hasError = !hasStyles && (communicationStyles?.lastUpdated ?? 0) !== 0;
		const canRequestInsights = !hasStyles && (communicationStyles?.lastUpdated ?? 0) === 0;

		if (isLoadingInsights) {
			setDisplayState('loading');
		} else if (hasStyles) {
			setDisplayState('showStyles');
			const lookup = getInsightsLookup();
			const style = newStylesMap.primaryStyle ?? newStylesMap.secondaryStyle;
			setCommunicationTips({
				communicationDos: lookup[style as CommunicationStyle].messaging.dos,
				communicationDonts: lookup[style as CommunicationStyle].messaging.donts,
			});
		} else if (hasError) {
			setDisplayState('showError');
		} else if (canRequestInsights) {
			setDisplayState('showRequestButton');
		} else {
			setDisplayState('loading');
		}
	}, [communicationStyles, isLoadingInsights]);

	async function onRequestInsights() {
		setIsProcessingRequest(true);
		try {
			await handleRequestInsights?.();
		} catch (e: unknown) {
			console.error(e);
		} finally {
			setIsProcessingRequest(false);
		}
	}

	function getRequestInsightsButton() {
		return (
			<AiButton
				data-testid={PERSONALIZED_INSIGHTS_BUTTON_TEST_ID}
				className={styles.generateInsightsButton}
				size={AI_TAG_SIZES.MEDIUM}
				isLoading={isProcessingRequest}
				onClick={() => void onRequestInsights()}
				data-tracking-id={DRAWER_VIEW_INSIGHTS_TRACKING_ID}
			>
				Generate Insights
			</AiButton>
		);
	}
	function onSeeMoreTips() {
		return handleSeeMoreTips?.() ?? Promise.resolve();
	}

	function getCommunicationStyleClass(style: CommunicationStyle | undefined) {
		if (isNil(style)) {
			return '';
		}
		switch (style) {
			case 'director':
				return styles.director;
			case 'socializer':
				return styles.socializer;
			case 'thinker':
				return styles.thinker;
			case 'relator':
				return styles.relator;
		}
	}

	const { primaryStyle, secondaryStyle } = stylesMap;

	return (
		<>
			<div className={styles.personalizedInsightsV2Wrapper}>
				<div className={styles.titleSection}>
					<Title className={styles.infoPanelTitle} level={4}>
						Personalized Insights
					</Title>
					<Tooltip
						overlayClassName={styles.helpToolTip}
						placement="right"
						title={
							"Analyze a prospect's primary and secondary communication styles to tailor your approach for better conversations."
						}
					>
						<QuestionCircleOutlined className={styles.helpIcon} />
					</Tooltip>
				</div>
				{displayState === 'loading' && (
					<Skeleton
						active
						paragraph={{ rows: 4, className: styles.loadingSkeleton }}
						title={false}
					/>
				)}
				{displayState === 'showError' && (
					<div className={styles.errorWrapper}>
						<CloseCircleFilled className={styles.errorIcon} />
						<Text className={styles.errorDescription}>
							There was an error analyzing this prospect's communication styles. Check
							back again later.
						</Text>
					</div>
				)}
				{displayState === 'showRequestButton' && (
					<div className={styles.generateInsightsWrapper}>
						{getRequestInsightsButton()}
						<Text className={styles.generateInsightsDescription}>
							Only messages from after August 2024 are analyzed automatically. For
							earlier messages, click the button to analyze.
						</Text>
					</div>
				)}
				{displayState === 'showStyles' && (
					<div className={styles.communicationStyleWrapper}>
						{!isNil(primaryStyle) && (
							<div
								className={[
									styles.communicationStyleIndicator,
									getCommunicationStyleClass(primaryStyle),
								].join(' ')}
							>
								<CommunicationStyleIcon style={primaryStyle} />
								<span>
									Primary Style:{' '}
									<b>{getCommunicationStyleDisplayText(primaryStyle)}</b>
								</span>
								<AiIcon className={styles.aiIcon} />
							</div>
						)}
						{!isNil(secondaryStyle) && (
							<div
								className={[
									styles.communicationStyleIndicator,
									getCommunicationStyleClass(secondaryStyle),
								].join(' ')}
							>
								<CommunicationStyleIcon style={secondaryStyle} />
								Secondary Style: {getCommunicationStyleDisplayText(secondaryStyle)}
								<AiIcon className={styles.aiIcon} />
							</div>
						)}
						<div
							className={[
								styles.communicationStyleDescriptorTags,
								getCommunicationStyleClass(primaryStyle ?? secondaryStyle),
							].join(' ')}
						>
							{!isNil(primaryStyle) &&
								getCommunicationStyleDescriptorTags(primaryStyle)}
						</div>
					</div>
				)}
			</div>
			{displayState === 'showStyles' && (
				<>
					<Divider className={styles.divider} />
					<CommunicationTipsCollapsableSection
						communicationDos={communicationTips.communicationDos}
						communicationDonts={communicationTips.communicationDonts}
						onSeeMoreTips={onSeeMoreTips}
					/>
				</>
			)}
		</>
	);
}
