// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".k0a5lRhDYzjRCfT70DgC {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: visible;\n  display: inline-flex;\n}\n.k0a5lRhDYzjRCfT70DgC .T3OQyUUaWl51WluSBSd6 {\n  color: #1677ff;\n}\n.k0a5lRhDYzjRCfT70DgC .GBRVQ92ukH9sg_zRZdDM {\n  color: #e57700;\n}\n.k0a5lRhDYzjRCfT70DgC .o5dX_0WN0g2P8kYrWmTk {\n  color: #722ed1;\n}\n.k0a5lRhDYzjRCfT70DgC .Mre1258sN0D0ug04uFYy {\n  color: #08979c;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/ai/personalizedInsights/communicationStyleIcon.module.less"],"names":[],"mappings":"AAEA;EAaC,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,oBAAA;AAbD;AAJA;EAEE,cAAA;AAKF;AAPA;EAKE,cAAA;AAKF;AAVA;EAQE,cAAA;AAKF;AAbA;EAWE,cAAA;AAKF","sourcesContent":["@import '@copilot/common/pages/personalizedInsightsV2/common/personalizedInsightsV2Variables.less';\n\n.communicationStyleIcon {\n\t.director {\n\t\tcolor: @director-primary-color;\n\t}\n\t.socializer {\n\t\tcolor: @socializer-primary-color;\n\t}\n\t.thinker {\n\t\tcolor: @thinker-primary-color;\n\t}\n\t.relator {\n\t\tcolor: @relator-primary-color;\n\t}\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\toverflow: visible;\n\tdisplay: inline-flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"communicationStyleIcon": "k0a5lRhDYzjRCfT70DgC",
	"director": "T3OQyUUaWl51WluSBSd6",
	"socializer": "GBRVQ92ukH9sg_zRZdDM",
	"thinker": "o5dX_0WN0g2P8kYrWmTk",
	"relator": "Mre1258sN0D0ug04uFYy"
};
export default ___CSS_LOADER_EXPORT___;
