import { CommunicationStyle } from '@copilot/common/pages/personalizedInsightsV2/common/personalizedInsightsV2Types';
import { isNil } from 'lodash';

/**
 * Get the display text for a communication style
 * @param style - The communication style to get the display text for
 * @returns The display text for the communication style
 */
export function getCommunicationStyleDisplayText(style: CommunicationStyle | undefined) {
	if (isNil(style)) {
		return '';
	}
	return style.charAt(0).toUpperCase() + style.slice(1);
}
