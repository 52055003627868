import { useRef, useState } from 'react';
import { Button, Input, InputRef, Typography } from 'antd';
import LoadingIndicator from '@copilot/common/components/loadingIndicator/spinner';
import styles from './prospectInfo.module.less';
import { CopyOutlined } from '@ant-design/icons';
import notificationManager from '@copilot/common/utils/notificationManager';

type ProspectInfoBoxPropsType = {
	/**
	 * Information box's icon
	 */
	icon: JSX.Element;
	/**
	 * Placeholder to display if the field has no value
	 */
	placeholder: string;
	/**
	 * Prospect information
	 */
	text?: string;
	/**
	 * The field is being updated
	 */
	isLoading: boolean;
	/**
	 * Handler for updating the field
	 * @param value
	 */
	onUpdate: (value: string) => void;
};

/**
 * Box providing information on a prospect.
 * @param props
 * @constructor
 */
export function ProspectInfoInputBox({
	isLoading,
	icon,
	placeholder,
	text,
	onUpdate,
}: ProspectInfoBoxPropsType) {
	const [value, setValue] = useState<string | undefined>(text);
	const [isFocused, setIsFocused] = useState<boolean>(false);
	const inputRef = useRef<InputRef>(null);

	function onCopy() {
		navigator.clipboard.writeText(value ?? '');
		notificationManager.showSuccessNotification({
			message: 'Copied to clipboard',
		});
	}

	return (
		<div className={styles.prospectInfoBox}>
			<LoadingIndicator
				isLoading={isLoading}
				// Override to hide "Loading..." from the spinner
				tip={''}
			>
				<Input
					ref={inputRef}
					prefix={icon}
					placeholder={placeholder}
					value={value}
					onChange={(event) => setValue(event.target.value)}
					onFocus={() => {
						setIsFocused(true);
					}}
					onBlur={() => {
						setValue(text);
						setIsFocused(false);
					}}
					onPressEnter={() => {
						onUpdate(value ?? '');
						setIsFocused(false);
					}}
					disabled={isLoading}
				/>
				{!isFocused && (
					<Button
						className={styles.copyButton}
						type="text"
						icon={<CopyOutlined />}
						onClick={onCopy}
					/>
				)}
			</LoadingIndicator>
			<div className={isFocused ? styles.open : styles.hidden}>
				<Typography.Text type="secondary">Press enter to save</Typography.Text>
			</div>
		</div>
	);
}
