import {
	IOrganizationContactInformationQuery,
	useOrganizationContactInformationQuery,
	useUpdateOrgContactCompanyMutation,
	useUpdateOrgContactEmailMutation,
	useUpdateOrgContactPhoneNumberMutation,
	useUpdateOrgContactTitleMutation,
} from '@copilot/data/graphql/_generated';
import { compact, isEmpty, isNil, keyBy, isUndefined, first } from 'lodash';
import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { CampaignConnectionModel, CampaignHistoryModel, OrgContactModel } from '../model';
import { useEffect, useState } from 'react';
import { ConnectionResponse } from '@copilot/data/responses/interface';
import { IConversationThread } from '@copilot/common/store/models/redux';
import { OrganizationContactQueryResultType } from './types';

/**
 * Combine first name and last name into the full name
 * @param firstName
 * @param lastName
 */
const toName = (firstName: string | undefined, lastName: string | undefined): string => {
	return compact([firstName, lastName]).join(' ');
};

type CampaignConnectionDTO = ArrayElement<
	IOrganizationContactInformationQuery['campaignConnections']
>;

/**
 * Convert dto to campaign connection model
 * @param campaignConnectionDto
 * @param orgMemberByMemberId
 */
const toCampaignConnectionModel = <T extends CampaignConnectionDTO>(
	campaignConnectionDto: T,
	orgMemberByMemberId: Record<string, { firstName: string; lastName: string }>
): CampaignConnectionModel => {
	const orgMemberId = campaignConnectionDto.campaignMember.orgMemberId;
	const orgMember = orgMemberByMemberId[orgMemberId];
	const campaignHistory = campaignConnectionDto.campaignHistory
		? campaignConnectionDto.campaignHistory.map(toCampaignHistoryModel)
		: [];
	/// Use - as name if we cannot get the organization member's name
	const orgMemberName = isUndefined(orgMember)
		? '-'
		: toName(orgMember.firstName, orgMember.lastName);
	return {
		id: campaignConnectionDto.id,
		orgMemberName,
		orgMemberId,
		campaignHistory,
		campaignId: campaignConnectionDto.campaignId,
		campaignMemberId: campaignConnectionDto.campaignMember.id,
		meeting: {
			bookedDate: isNil(campaignConnectionDto?.meeting?.bookedDate)
				? undefined
				: campaignConnectionDto.meeting.bookedDate,
		},
		communicationStyles: campaignConnectionDto.communicationStyles ?? undefined,
	};
};

/**
 * Convert the graphql campaignHistory return type to CampaignHistoryModel
 * @param history
 */
const toCampaignHistoryModel = (history: {
	start: number;
	end?: number | null | undefined;
	name: string;
}): CampaignHistoryModel => {
	return {
		start: history.start,
		end: isNil(history.end) ? undefined : history.end,
		name: history.name,
	};
};

type ContactDTO = ArrayElement<IOrganizationContactInformationQuery['orgContacts']>;

/**
 * Convert a contactDTO to OrgContactModel
 * @param contactDTO
 * @returns
 */
const toOrgContactModel = <T extends ContactDTO>(contactDTO: T): OrgContactModel => {
	return {
		id: contactDTO.id,
		firstName: contactDTO.firstName,
		lastName: contactDTO.lastName,
		title: contactDTO.title,
		company: contactDTO.company,
		email: contactDTO.email,
		phoneNumber: contactDTO.phoneNumber,
	};
};

/**
 * Encapsulate logics to determine whether or not the current user should be able to see the
 * new prospect drawer for the meeting booked feature
 */
export const useNewProspectDrawer = (): boolean => {
	/// CS should not see the new prospect drawer when impersonating
	const isCSImpersonating = useSelector(OrganizationMemberSelectors.getAdminMember);
	return isNil(isCSImpersonating);
};

/**
 * Encapsulate logics to determine whether or not the current user should be able to see the
 * reporting & history features for the new prospect drawer.
 */
export const useNewProspectDrawerReportingAndHistory = (): boolean => {
	/// CS should not see the history panel when impersonating
	const isCSImpersonating = useSelector(OrganizationMemberSelectors.getAdminMember);
	return isNil(isCSImpersonating);
};

/**
 * Returns the organization contact's information
 * @param contactId
 */
export const useOrganizationContact = (
	contactId: string,
	handleContactUpdate: (update: OrgContactModel) => void
): OrganizationContactQueryResultType => {
	const orgMembers = useSelector(OrganizationMemberSelectors.getAllOrgMembers);

	const [contact, setContact] = useState<OrgContactModel | undefined>();

	const { data, loading } = useOrganizationContactInformationQuery({
		variables: {
			contactId,
		},
		skip: isEmpty(contactId),
	});

	const [updateTitleMutation, { loading: isContactTitleUpdating }] =
		useUpdateOrgContactTitleMutation();

	const onContactTitleUpdate = async (title: string) => {
		const { data: responseData } = await updateTitleMutation({
			variables: { title, orgContactId: contactId },
		});
		const updated = responseData?.updateOrgContactTitle;
		if (isNil(updated)) return;
		const updatedModel = toOrgContactModel(updated);
		setContact((prevState) => ({ ...prevState, ...updatedModel }));
		handleContactUpdate(updatedModel);
	};

	const [updateCompanyMutation, { loading: isContactCompanyUpdating }] =
		useUpdateOrgContactCompanyMutation();

	const onContactCompanyUpdate = async (company: string) => {
		const { data: responseData } = await updateCompanyMutation({
			variables: { company, orgContactId: contactId },
		});
		const updated = responseData?.updateOrgContactCompany;
		if (isNil(updated)) return;
		const updatedModel = toOrgContactModel(updated);
		setContact((prevState) => ({ ...prevState, ...updatedModel }));
		handleContactUpdate(updatedModel);
	};

	const [updateEmailMutation, { loading: isContactEmailUpdating }] =
		useUpdateOrgContactEmailMutation();

	const onContactEmailUpdate = async (email: string) => {
		const { data: responseData } = await updateEmailMutation({
			variables: { email, orgContactId: contactId },
		});
		const updated = responseData?.updateOrgContactEmail;
		if (isNil(updated)) return;
		const updatedModel = toOrgContactModel(updated);
		setContact((prevState) => ({ ...prevState, ...updatedModel }));
		handleContactUpdate(updatedModel);
	};

	const [updatePhoneNumberMutation, { loading: isContactPhoneNumberUpdating }] =
		useUpdateOrgContactPhoneNumberMutation();

	const onContactPhoneNumberUpdate = async (phoneNumber: string) => {
		const { data: responseData } = await updatePhoneNumberMutation({
			variables: { phoneNumber, orgContactId: contactId },
		});
		const updated = responseData?.updateOrgContactPhoneNumber;
		if (isNil(updated)) return;
		const updatedModel = toOrgContactModel(updated);
		setContact((prevState) => ({ ...prevState, ...updatedModel }));
		handleContactUpdate(updatedModel);
	};

	useEffect(() => {
		const orgContact = first(data?.orgContacts);
		if (isNil(orgContact)) return;
		setContact((prevState) => ({ ...prevState, ...toOrgContactModel(orgContact) }));
	}, [data]);

	// contact will always be undefined if the initial query failed, causing the panel to stuck at loading state
	if (loading || isNil(contact))
		return {
			loading: true,
			campaignConnections: undefined,
			contact: undefined,
			isContactTitleUpdating: false,
			isContactCompanyUpdating: false,
			isContactEmailUpdating: false,
			isContactPhoneNumberUpdating: false,
			onContactTitleUpdate: undefined,
			onContactCompanyUpdate: undefined,
			onContactEmailUpdate: undefined,
			onContactPhoneNumberUpdate: undefined,
		};
	const connections = data?.campaignConnections;

	if (isNil(connections)) {
		throw new Error('OrgContacts Query failed to return expected data.');
	}

	const orgMemberByMemberId = keyBy(orgMembers, (member) => member.id);

	const campaignConnections = connections.map((connection) =>
		toCampaignConnectionModel(connection, orgMemberByMemberId)
	);

	return {
		loading: false,
		campaignConnections,
		contact,
		onContactTitleUpdate,
		onContactPhoneNumberUpdate,
		onContactEmailUpdate,
		onContactCompanyUpdate,
		isContactTitleUpdating,
		isContactPhoneNumberUpdating,
		isContactEmailUpdating,
		isContactCompanyUpdating,
	};
};

/**
 * Possible pipeline states
 */
export const PipelineStateNames = {
	/**
	 * Pipeline state for a connection that has been messaged
	 */
	Messaged: 'Messaged',
	/**
	 * Pipeline state for a connection that has replied to the conversation
	 */
	Replied: 'Replied',
	/**
	 * Pipeline state for a connection that has a meeting booked
	 */
	Meeting: 'Meeting',
} as const;

/**
 * Current state of the pipeline
 */
type PipelineState = Readonly<{
	[PipelineStateNames.Messaged]: boolean;
	[PipelineStateNames.Replied]: boolean;
	[PipelineStateNames.Meeting]: boolean;
}>;
/**
 * Returns the state of a connection's pipeline progress
 * @param connection The connection we want the pipeline state for
 * @param thread The linkedin thread between the team member and the connection
 */
export const usePipelineState = (
	connection: ConnectionResponse | undefined,
	thread: IConversationThread | null
): PipelineState => {
	const replied = !isEmpty(thread?.receivedMessages);
	const messaged = !isEmpty(thread?.sentMessages);
	const meeting = connection?.meeting.booked === true;
	return {
		[PipelineStateNames.Messaged]: messaged,
		[PipelineStateNames.Replied]: replied,
		[PipelineStateNames.Meeting]: meeting,
	};
};
