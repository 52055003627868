import { CommunicationStyle } from '@copilot/common/pages/personalizedInsightsV2/common/personalizedInsightsV2Types';

import styles from './communicationStyleIcon.module.less';

type CommunicationStyleIconProps = {
	style: CommunicationStyle;
	size?: number;
};

/**
 * The communication style icon, used to display the communication style of a prospect
 * @param props
 * @constructor
 */
export function CommunicationStyleIcon({ style, size = 16 }: CommunicationStyleIconProps) {
	return (
		<div
			className={styles.communicationStyleIcon}
			style={{
				width: size,
				height: size,
			}}
		>
			{style === 'director' && (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="100%"
					height="100%"
					viewBox="0 0 16 17"
					preserveAspectRatio="xMidYMid meet"
					fill="none"
					className={styles.director}
				>
					<path
						d="M14.672 2.25387C12.9014 0.463546 10.0306 0.463558 8.25995 2.25387C8.11639 2.39903 7.88361 2.39903 7.74005 2.25387C5.96941 0.463558 3.09865 0.463546 1.328 2.25387C-0.442656 4.0442 -0.442667 6.94689 1.32797 8.7372C1.47154 8.88236 1.47156 9.1177 1.32802 9.26283C-0.442632 11.0532 -0.442656 13.9558 1.328 15.7462C3.09865 17.5365 5.96945 17.5365 7.7401 15.7462C7.88364 15.601 8.11636 15.601 8.2599 15.7462C10.0306 17.5365 12.9014 17.5365 14.672 15.7462C16.4427 13.9558 16.4426 11.0532 14.672 9.26283C14.5284 9.1177 14.5285 8.88236 14.672 8.7372C16.4427 6.94689 16.4427 4.0442 14.672 2.25387Z"
						fill="currentColor"
					/>
				</svg>
			)}
			{style === 'socializer' && (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="100%"
					height="100%"
					viewBox="0 0 16 16"
					preserveAspectRatio="xMidYMid meet"
					fill="none"
					className={styles.socializer}
				>
					<path
						d="M15.9999 3.0961C15.9999 1.43524 14.601 0.0366879 13.0745 0.69105C12.147 1.08862 11.3043 1.67135 10.5945 2.40596C9.88462 3.14058 9.32154 4.01269 8.93737 4.97251C8.5532 5.93233 8.35547 6.96106 8.35547 7.99996C8.35547 9.03886 8.5532 10.0676 8.93737 11.0274C9.32154 11.9872 9.88462 12.8593 10.5945 13.594C11.3043 14.3286 12.147 14.9113 13.0745 15.3089C14.601 15.9632 15.9999 14.5647 15.9999 12.9038L15.9999 3.0961Z"
						fill="currentColor"
					/>
					<path
						d="M8.82149e-05 3.0961C8.82149e-05 1.43524 1.39897 0.0366859 2.92549 0.691049C3.85296 1.08862 4.69567 1.67135 5.40553 2.40596C6.11538 3.14058 6.67846 4.01269 7.06263 4.97251C7.4468 5.93233 7.64453 6.96106 7.64453 7.99996C7.64453 9.03886 7.4468 10.0676 7.06263 11.0274C6.67846 11.9872 6.11538 12.8593 5.40553 13.594C4.69567 14.3286 3.85296 14.9113 2.92549 15.3089C1.39897 15.9632 8.7738e-05 14.5647 8.7738e-05 12.9038L8.82149e-05 3.0961Z"
						fill="currentColor"
					/>
				</svg>
			)}
			{style === 'thinker' && (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="100%"
					height="100%"
					viewBox="0 0 16 16"
					preserveAspectRatio="xMidYMid meet"
					fill="none"
					className={styles.thinker}
				>
					<path
						d="M7.49262 0.262575C7.74197 -0.0875248 8.25803 -0.0875253 8.50738 0.262575L9.49235 1.64553C9.65945 1.88015 9.96311 1.97016 10.2293 1.86398L11.7986 1.23812C12.1959 1.07968 12.63 1.36132 12.6523 1.79193L12.7402 3.4929C12.7551 3.78147 12.9624 4.02291 13.2432 4.07889L14.8986 4.40883C15.3176 4.49236 15.532 4.96622 15.3201 5.34062L14.4831 6.81956C14.3411 7.07047 14.3862 7.38669 14.5924 7.58705L15.8083 8.76804C16.1161 9.06701 16.0427 9.58264 15.6639 9.78197L14.1677 10.5693C13.9138 10.7029 13.7824 10.9935 13.8486 11.2746L14.2389 12.9317C14.3377 13.3512 13.9998 13.7449 13.5744 13.7059L11.894 13.5517C11.6089 13.5255 11.3427 13.6982 11.2479 13.9709L10.6887 15.5779C10.5472 15.9848 10.0521 16.1315 9.7151 15.8665L8.38405 14.8197C8.15824 14.6421 7.84176 14.6421 7.61595 14.8197L6.2849 15.8665C5.94794 16.1315 5.4528 15.9848 5.31126 15.5779L4.75215 13.9709C4.6573 13.6982 4.39106 13.5255 4.10598 13.5517L2.42559 13.7059C2.0002 13.7449 1.66225 13.3512 1.76107 12.9317L2.15142 11.2746C2.21764 10.9935 2.08617 10.7029 1.83234 10.5693L0.336117 9.78197C-0.0426573 9.58264 -0.1161 9.06701 0.191702 8.76804L1.40757 7.58705C1.61384 7.38669 1.65888 7.07047 1.51688 6.81956L0.67987 5.34062C0.467977 4.96622 0.682352 4.49236 1.10141 4.40883L2.75677 4.07889C3.0376 4.02291 3.24485 3.78147 3.25977 3.4929L3.34771 1.79193C3.36998 1.36132 3.80411 1.07968 4.20138 1.23812L5.77066 1.86398C6.03689 1.97016 6.34055 1.88015 6.50765 1.64553L7.49262 0.262575Z"
						fill="currentColor"
					/>
				</svg>
			)}
			{style === 'relator' && (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="100%"
					height="100%"
					viewBox="0 0 16 16"
					preserveAspectRatio="xMidYMid meet"
					fill="none"
					className={styles.relator}
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M8.00098 0.23999C9.60565 0.23999 10.9065 1.54448 10.9065 3.15365C10.9065 4.76282 9.60565 6.0673 8.00098 6.0673C6.39632 6.0673 5.09551 4.76282 5.09551 3.15365C5.09551 1.54448 6.39632 0.23999 8.00098 0.23999ZM3.14706 15.2033C1.84887 14.2575 1.56109 12.4354 2.50428 11.1335C3.44746 9.83165 5.26444 9.54307 6.56264 10.4889C7.86084 11.4347 8.1486 13.2569 7.20545 14.5587C6.26224 15.8606 4.44524 16.1492 3.14706 15.2033ZM13.5071 11.1335C14.4503 12.4354 14.1625 14.2575 12.8643 15.2033C11.5661 16.1492 9.74914 15.8606 8.80599 14.5587C7.86278 13.2569 8.15054 11.4347 9.44874 10.4889C10.7469 9.54307 12.5639 9.83165 13.5071 11.1335ZM0.142989 5.95105C0.638852 4.42066 2.27798 3.58312 3.8041 4.08039C5.33021 4.57765 6.16539 6.22142 5.66953 7.75181C5.17366 9.2822 3.53452 10.1197 2.00841 9.62248C0.482302 9.12522 -0.352875 7.4815 0.142989 5.95105ZM12.1959 4.08039C13.7221 3.58312 15.3611 4.42066 15.857 5.95105C16.3529 7.4815 15.5177 9.12522 13.9916 9.62248C12.4655 10.1197 10.8264 9.2822 10.3305 7.75181C9.83463 6.22142 10.6698 4.57765 12.1959 4.08039Z"
						fill="currentColor"
					/>
				</svg>
			)}
		</div>
	);
}
